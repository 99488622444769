// src/App.js or src/App.tsx
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "../src/Pages/Home";
import Registration from "../src/Pages/Registration";
import Attendance from "../src/Pages/Attendance";

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/registration' element={<Registration />} />
        <Route path='/attendance' element={<Attendance />} />
        <Route path='*' element={<Navigate to='/' />} />{" "}
        {/* Redirect unknown routes */}
      </Routes>
    </Router>
  );
}

export default App;
