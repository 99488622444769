import React, { useState } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import awsBackground from "../assets/aws_background.png"; // Import the background image
import "./Attendance.css"; // Import the CSS file

// Setting up the root element for React Modal
Modal.setAppElement("#root");

const Attendance = () => {
  const [email, setEmail] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState<{
    icon: IconProp;
    message: string;
    success: boolean;
  }>({
    icon: faTimesCircle,
    message: "",
    success: false,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://awswestafricausergroup.info/attendance",
        { email, isPresent: true }
      );
      if (response.status === 200) {
        setModalContent({
          icon: faCheckCircle,
          message: "You have marked your attendance successfully!",
          success: true,
        });
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (
          error.response &&
          (error.response.status === 400 || error.response.status === 403)
        ) {
          setModalContent({
            icon: faTimesCircle,
            message: "Invalid email. Please try again.",
            success: false,
          });
        } else {
          setModalContent({
            icon: faTimesCircle,
            message: "Something went wrong. Please try again later.",
            success: false,
          });
        }
      } else {
        setModalContent({
          icon: faTimesCircle,
          message: "An unexpected error occurred. Please try again later.",
          success: false,
        });
      }
    } finally {
      setModalIsOpen(true);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    if (modalContent.success) {
      window.location.href = "/"; // Redirect to home on success
    }
  };

  return (
    <section
      id='attendance'
      style={{
        backgroundImage: `url(${awsBackground})`,
      }} // Add the background style here
    >
      <div className='attendance-container'>
        <h1 className='attendance-title'>Take Attendance</h1>
        <p className='attendance-description'>
          Please make sure you have registered in order to take attendance.
        </p>
        <form onSubmit={handleSubmit} className='attendance-form'>
          <input
            type='email'
            placeholder='Enter email'
            value={email}
            onChange={handleInputChange}
            className='attendance-input'
            required
          />
          <button type='submit' className='attendance-button'>
            Mark Attendance
          </button>
        </form>
        <p className='attendance-note'>
          Make sure to enter the e-mail used during registration.
        </p>
      </div>

      {/* Success/Error Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        overlayClassName='modal-overlay'
        className='modal-content'>
        <button onClick={closeModal} className='modal-close-button'>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <FontAwesomeIcon
          icon={modalContent.icon}
          className={`modal-icon ${
            modalContent.success ? "modal-icon-success" : "modal-icon-error"
          }`}
        />
        <p className='modal-message'>{modalContent.message}</p>
        <button
          onClick={closeModal}
          className={`modal-button ${
            modalContent.success ? "modal-button-success" : "modal-button-error"
          }`}>
          Close
        </button>
      </Modal>
    </section>
  );
};

export default Attendance;
