import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import awsBackground from "../assets/aws_background.png";
import logo from "../assets/logo.png";
import logo_2 from "../assets/logo_2.png";
import header from "../assets/header.png";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import "../App.css";
import { ClipLoader } from "react-spinners";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  middleName: Yup.string(), // Made optional by removing the required validator
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  currentStatus: Yup.string(),
  jobTitle: Yup.string().when("currentStatus", {
    is: "Working Professional",
    then: (schema) => schema,
  }),
  company: Yup.string().when("currentStatus", {
    is: "Working Professional",
    then: (schema) => schema,
  }),
  industry: Yup.string().when("currentStatus", {
    is: "Working Professional",
    then: (schema) => schema,
  }),
  experience: Yup.number().when("currentStatus", {
    is: "Working Professional",
    then: (schema) => schema,
  }),
  attendanceType: Yup.string().required("Attendance Type is required"),
  eventAwareness: Yup.string().required("This field is required"),
  awsUserGroupMember: Yup.string().required("This field is required"),
  awsCommunityBuilder: Yup.string().required("This field is required"),
  volunteering: Yup.string().required("This field is required"),
  photographyConsent: Yup.string().required("This field is required"),
  agreement: Yup.string().required("This field is required"),
  referralCode: Yup.string().optional(),
});

const App: React.FC = () => {
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [modalMessage, setModalMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const onChange = (token: string) => {
    console.log("reCAPTCHA token:", token);
    setRecaptchaVerified(true);
  };

  const currentStatus = watch("currentStatus");

  const onSubmit = async (data: any) => {
    setLoading(true); // Start loading animation

    // Convert Yes/No values to true/false
    data.awsUserGroupMember = data.awsUserGroupMember === "Yes";
    data.awsCommunityBuilder = data.awsCommunityBuilder === "Yes";
    data.volunteering = data.volunteering === "Yes";
    data.photographyConsent = data.photographyConsent === "Yes";
    data.agreement = data.agreement === "Yes";

    // Set optional fields to null if not filled
    data.middleName = data.middleName || null;
    data.jobTitle = data.jobTitle || null;
    data.company = data.companyName || null;
    data.industry = data.industry || null;
    data.experience = data.experience || null;
    data.referralCode = data.referralCode || null;

    // Handle form submission
    console.log("Form values:", data);

    const jsonData = JSON.stringify(data);

    console.log(jsonData);
    axios
      .post("https://awswestafricausergroup.info/submit", jsonData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        setLoading(false); // Stop loading animation
        if (response.status === 200) {
          //   setSubmissionMessage(response.data.message);
          setIsSubmitted(true);
        }
      })
      .catch((error) => {
        console.error("submit", error);
        setLoading(false); // Stop loading animation
        if (error.response && error.response.status === 400) {
          setModalMessage(
            "Email already exists. Please use a different email."
          );
        }
      });
  };

  const closeModal = () => {
    setModalMessage("");
    window.location.reload();
  };

  return (
    <div
      className='min-h-screen bg-cover bg-center bg-norepeat flex items-center justify-center px-8 py-9 gap-6'
      style={{ backgroundImage: `url(${awsBackground})` }}>
      {!isSubmitted ? (
        <>
          <div className='absolute top-0 left-0 m-4 flex2  gap-4 '>
            <img src={logo} alt='Logo' className='logo' />
            <img src={logo_2} alt='Logo' className='logo' />
          </div>
          <div className='flex justify-center mb-8'>
            <img
              src={header}
              alt='Header'
              className='header'
              width={"width-full"}
              height={"200px"}
            />
          </div>
          <div className='bg-white py-4 px-6 rounded-l shadow-lg max-w-5xl w-full mt-7'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <h2 className='text-2xl font-bold '>Personal Information</h2>
              <div className='grid grid-cols-3 gap-2'>
                <div>
                  <input
                    type='text'
                    placeholder='First Name'
                    {...register("firstName")}
                    className={`input-field ${
                      errors.firstName ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.firstName && (
                    <div className='text-red-500 text-sm'>
                      {errors.firstName.message}
                    </div>
                  )}
                </div>
                <div>
                  <input
                    type='text'
                    placeholder='Last Name'
                    {...register("lastName")}
                    className={`input-field ${
                      errors.lastName ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.lastName && (
                    <div className='text-red-500 text-sm'>
                      {errors.lastName.message}
                    </div>
                  )}
                </div>
                <div>
                  <input
                    type='text'
                    placeholder='Middle Name (Optional)'
                    {...register("middleName")}
                    className={`input-field ${
                      errors.middleName ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.middleName && (
                    <div className='text-red-500 text-sm'>
                      {errors.middleName.message}
                    </div>
                  )}
                </div>
                <div className='col-span-3'>
                  <input
                    type='email'
                    placeholder='Email'
                    {...register("email")}
                    className={`input-field ${
                      errors.email ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.email && (
                    <div className='text-red-500 text-sm'>
                      {errors.email.message}
                    </div>
                  )}
                </div>
              </div>

              <h2 className='text-2xl font-bold mb-4 mt-6'>Current Status</h2>
              <div className='mb-4'>
                <select
                  {...register("currentStatus")}
                  className={`w-full px-4 py-2 border rounded ${
                    errors.currentStatus ? "border-red-500" : "border-gray-300"
                  }`}>
                  <option value=''>Select your current status</option>
                  <option value='Student'>Student</option>
                  <option value='Working Professional'>
                    Working Professional
                  </option>
                  <option value='Lifelong Learner'>Lifelong Learner</option>
                </select>
                {errors.currentStatus && (
                  <div className='text-red-500 text-sm'>
                    {errors.currentStatus.message}
                  </div>
                )}
              </div>

              {currentStatus === "Working Professional" && (
                <>
                  <h2 className='text-2xl font-bold mb-4 mt-6'>
                    Professional Information
                  </h2>
                  <div className='grid grid-cols-1 gap-4'>
                    <div>
                      <input
                        type='text'
                        placeholder='Job Title'
                        {...register("jobTitle")}
                        className={`input-field ${
                          errors.jobTitle ? "border-red-500" : "border-gray-300"
                        }`}
                      />
                      {errors.jobTitle && (
                        <div className='text-red-500 text-sm'>
                          {errors.jobTitle.message}
                        </div>
                      )}
                    </div>
                    <div>
                      <input
                        type='text'
                        placeholder='Company/Organization Name'
                        {...register("company")}
                        className={`input-field ${
                          errors.company ? "border-red-500" : "border-gray-300"
                        }`}
                      />
                      {errors.company && (
                        <div className='text-red-500 text-sm'>
                          {errors.company.message}
                        </div>
                      )}
                    </div>
                    <div>
                      <input
                        type='text'
                        placeholder='Industry (e.g., Technology, Finance, Healthcare, Education, etc.)'
                        {...register("industry")}
                        className={`input-field ${
                          errors.industry ? "border-red-500" : "border-gray-300"
                        }`}
                      />
                      {errors.industry && (
                        <div className='text-red-500 text-sm'>
                          {errors.industry.message}
                        </div>
                      )}
                    </div>
                    <div>
                      <input
                        type='text'
                        placeholder='Years of Experience in the Industry'
                        {...register("experience")}
                        className={`input-field ${
                          errors.experience
                            ? "border-red-500"
                            : "border-gray-300"
                        }`}
                      />
                      {errors.experience && (
                        <div className='text-red-500 text-sm'>
                          {errors.experience.message}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              <h2 className='text-2xl font-bold mb-4 mt-6'>
                Attendance Details
              </h2>
              <div className='grid grid-cols-2 gap-4'>
                <div className='mb-4'>
                  <select
                    {...register("attendanceType")}
                    className={`w-full px-4 py-2 border rounded ${
                      errors.attendanceType
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}>
                    <option value=''>
                      Select attendance type for Day 2(Day 1 is virtual)
                    </option>
                    <option value='Physically'>Physically</option>
                    <option value='Virtually'>Virtually</option>
                  </select>
                  {errors.attendanceType && (
                    <div className='text-red-500 text-sm'>
                      {errors.attendanceType.message}
                    </div>
                  )}
                </div>
                <div className='mb-4'>
                  <select
                    {...register("eventAwareness")}
                    className={`w-full px-4 py-2 border rounded ${
                      errors.eventAwareness
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}>
                    <option value=''>
                      Select how you heard about the event
                    </option>
                    <option value='Website'>Website</option>
                    <option value='Social Media'>Social Media</option>
                    <option value='Email'>Email</option>
                    <option value='Friend/Colleague'>Friend/Colleague</option>
                  </select>
                  {errors.eventAwareness && (
                    <div className='text-red-500 text-sm'>
                      {errors.eventAwareness.message}
                    </div>
                  )}
                </div>
              </div>
              <div className='grid grid-cols-2 gap-4'>
                <small>Are you attending physically or virtually?</small>
                <small>How did you hear about this event?</small>
              </div>

              <h2 className='text-2xl font-bold'>Community Engagement</h2>
              <div className='grid grid-cols-1 gap-4'>
                <div>
                  <label>Are you a member of any AWS User Groups?</label>
                  <div className='radio-group'>
                    <label className='radio-option'>
                      <input
                        type='radio'
                        value='Yes'
                        {...register("awsUserGroupMember")}
                        className='mr-2'
                      />
                      Yes
                    </label>
                    <label className='radio-option'>
                      <input
                        type='radio'
                        value='No'
                        {...register("awsUserGroupMember")}
                        className='mr-2'
                      />
                      No
                    </label>
                  </div>
                  {errors.awsUserGroupMember && (
                    <div className='text-red-500 text-sm'>
                      {errors.awsUserGroupMember.message}
                    </div>
                  )}
                </div>
                <div>
                  <label>Are you an AWS community builder?</label>
                  <div className='radio-group'>
                    <label className='radio-option'>
                      <input
                        type='radio'
                        value='Yes'
                        {...register("awsCommunityBuilder")}
                        className='mr-2'
                      />
                      Yes
                    </label>
                    <label className='radio-option'>
                      <input
                        type='radio'
                        value='No'
                        {...register("awsCommunityBuilder")}
                        className='mr-2'
                      />
                      No
                    </label>
                  </div>
                  {errors.awsCommunityBuilder && (
                    <div className='text-red-500 text-sm'>
                      {errors.awsCommunityBuilder.message}
                    </div>
                  )}
                </div>
                <div>
                  <label>
                    Would you be interested in Partnering with us for future
                    events?
                  </label>
                  <div className='radio-group'>
                    <label className='radio-option'>
                      <input
                        type='radio'
                        value='Yes'
                        {...register("volunteering")}
                        className='mr-2'
                      />
                      Yes
                    </label>
                    <label className='radio-option'>
                      <input
                        type='radio'
                        value='No'
                        {...register("volunteering")}
                        className='mr-2'
                      />
                      No
                    </label>
                  </div>
                  {errors.volunteering && (
                    <div className='text-red-500 text-sm'>
                      {errors.volunteering.message}
                    </div>
                  )}
                </div>
              </div>

              <h2 className='text-2xl font-bold mb-4 mt-6'>Referral Code</h2>
              <div className='w-full'>
                <input
                  type='text'
                  placeholder='Referral Code (Optional)'
                  {...register("referralCode")}
                  className='input-field'
                />
              </div>

              <h2 className='text-2xl font-bold mb-4 mt-6'>Consent</h2>
              <div className='grid grid-cols-1 gap-4'>
                <div>
                  <label className='text-less'>
                    Do you consent to receive communications about this and
                    future events?
                  </label>
                  <div className='radio-group'>
                    <label className='radio-option'>
                      <input
                        type='radio'
                        value='Yes'
                        {...register("photographyConsent")}
                        className='mr-2'
                      />
                      Yes
                    </label>
                    <label className='radio-option'>
                      <input
                        type='radio'
                        value='No'
                        {...register("photographyConsent")}
                        className='mr-2'
                      />
                      No
                    </label>
                  </div>
                  {errors.photographyConsent && (
                    <div className='text-red-500 text-sm'>
                      {errors.photographyConsent.message}
                    </div>
                  )}
                </div>
                <div>
                  <label className='text-less'>
                    <a
                      className='terms'
                      href='https://docs.google.com/document/d/1uCMPiJ9VNwxMpQlAeIwIrIekZyFsS2_QO96zRRuT8_M/edit'>
                      Do you agree to the terms and conditions of participating
                      in this event?
                    </a>
                  </label>
                  <div className='radio-group'>
                    <label className='radio-option'>
                      <input
                        type='radio'
                        value='Yes'
                        {...register("agreement")}
                        className='mr-2'
                      />
                      Yes
                    </label>
                    <label className='radio-option'>
                      <input
                        type='radio'
                        value='No'
                        {...register("agreement")}
                        className='mr-2'
                      />
                      No
                    </label>
                  </div>
                  {errors.agreement && (
                    <div className='text-red-500 text-sm'>
                      {errors.agreement.message}
                    </div>
                  )}
                </div>
              </div>
              <div className='w-full mt-2 mb-2 recaptcha-container'>
                <ReCAPTCHA
                  sitekey='6LfeRxgqAAAAALjWdJwCA76-TX9qBK2S_LrkU-Ey'
                  onChange={onChange}
                />
              </div>

              <div className='flex1 align-end justify-between'>
                <div className='flex1 align space-x-4'>
                  <button
                    type='submit'
                    className='button'
                    style={{
                      backgroundColor: recaptchaVerified
                        ? "#2c255f"
                        : "#cccccc",
                      color: recaptchaVerified ? "white" : "#666666",
                      cursor: recaptchaVerified ? "pointer" : "not-allowed",
                    }}
                    disabled={isSubmitting || !recaptchaVerified}>
                    Submit
                  </button>
                  <a
                    href='https://www.meetup.com/aws-cloud-security-user-group-west-africa/'
                    className='button-join'>
                    Join Community
                  </a>
                </div>

                <a href='/' className='back_home'>
                  Back to Home
                </a>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className='min-h-screen bg-cover bg-center bg-norepeat flex items-center justify-center px-8 py-9'>
          <div className='bg-white py-4 px-6 rounded-l shadow-lg max-w-lg w-full mt-7 text-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-10 w-10 mx-auto mb-4 text-green-500'
              viewBox='0 0 20 20'
              fill='green'>
              <path
                fillRule='evenodd'
                d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-10.293a1 1 0 00-1.414-1.414L9 9.586 7.707 8.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                clipRule='evenodd'
              />
            </svg>
            <h2 className='text-2xl font-bold mb-4 '>
              You have successfully registered for the AWS Community Day!
            </h2>
            <p className='mb-4 '>
              Mark your calendars for a fun-filled day. Below is your referral
              code. Share and invite lots of friends, family, and colleagues to
              stand a chance to win different prizes.
            </p>
            <p className='text-blue-500'>
              Your referral code has been sent to your email.
            </p>
            <p className='text-red-600'>
              Note: If no email was sent, it means you inputted an invalid
              email. Please fill in with proper credentials.
            </p>
            <a href='/' className='register-button'>
              Back to Home
            </a>
          </div>
        </div>
      )}

      {loading && (
        <div className='modal-overlay'>
          <div className='modal-content'>
            <ClipLoader color='#241d4d' loading={loading} size={50} />
            <p>Loading...</p>
          </div>
        </div>
      )}

      {modalMessage && (
        <div className='modal-overlay'>
          <div className='modal-content'>
            <h2 className='text-2xl font-bold mb-4'>Error</h2>
            <p>{modalMessage}</p>
            <p>or Invalid Referral Code.</p>
            <button
              onClick={closeModal}
              className='button mt-4'
              style={{
                backgroundColor: "#2c255f",
                color: "white",
                cursor: "pointer",
              }}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
